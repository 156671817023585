import React, { useRef, useState } from 'react'
import { useImperativeHandle } from "react"
import { motion as m, AnimatePresence } from "framer-motion"
import axiosInstance from '../../axios/axiosInstance';


const Burn = React.forwardRef((props, ref) => {
    // States
    const [coupon, setCoupon] = useState();
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState();
    const [success, setSuccess] = useState(false);
    const [successText, setSuccessText] = useState('');
    const [copied, setCopied] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);


    // refs
    const burnPointsRef = useRef();
    const feedbackSpan = useRef();

    const { earnPointsBackButtonRef, earnPointsClosePopupRef, primaryColor, customerID, shopDomain } = props;

    useImperativeHandle(ref, () => ({
        earnPointsBackButtonRef,
        earnPointsClosePopupRef,

    }));



    const burnHandler = (e) => {
        e.preventDefault();
        setButtonDisabled(true); // Disable the button



        if (!coupon || coupon === "") {
            setError(true);
            setErrorText("Please enter a valid coupon number");
            setSuccess(false);
            setSuccessText("");
            // set timeout to enable the button after 3 seconds
            setTimeout(() => {
                setButtonDisabled(false);
            }, [500]
            );// enable the button
            return;
        }
        else {
            const header = {
                'Accept': 'application/json',
                'shop-domain': shopDomain
            }
            const body = {
                "PlatformCustomerId": customerID.toString(),

                "CouponNumber": coupon,
            }
            // hit verify coupon api endpoint
            axiosInstance.post('api/v1/dsquares/coupon/verify', body, { headers: header })
                .then((response) => {
                    if (response?.data?.status === "success") {
                        setSuccess(true);
                        setSuccessText(response.data.message);
                        setError(false);
                        setErrorText("");
                        // burnPointsRef.current.value = "";
                        // reset the copied state
                        setCopied(false);
                    }
                    else if (response?.data?.status === "failed") {
                        setError(true);
                        setErrorText(response.data.error);
                        setSuccess(false);
                        setSuccessText("");
                        // burnPointsRef.current.value = "";
                        setCoupon("");
                    }

                }).catch((error) => {
                    if (error?.response?.data?.status === "failed" && error?.response?.data?.code === 422) {
                        setError(true);
                        setErrorText(error.response.data.error);
                        setSuccess(false);
                        setSuccessText("");
                        burnPointsRef.current.value = "";
                    }
                    else if (error?.response?.data?.status === "failed" && error?.response?.data?.code === 500) {
                        setError(true);
                        setErrorText('Something went wrong. Please try again later! ');
                        setSuccess(false);
                        setSuccessText("");
                        burnPointsRef.current.value = "";
                    }
                    else if (error?.response?.data?.status === "failed" && error?.response?.data?.code === 400) {
                        setError(true);
                        setErrorText(error.response.data.error);
                        setSuccess(false);
                        setSuccessText("");
                        burnPointsRef.current.value = "";
                    }


                })
                .finally(() => {
                    setCoupon("");
                    setButtonDisabled(false); // enable the button
                });
        }


    }



    const copyToClipboard = (couponNumber) => {
        if (couponNumber === undefined || couponNumber === "") return;
        navigator.clipboard.writeText(couponNumber);
        setCopied(true);
    };



    return (
        <>
            <AnimatePresence>

                <m.div
                    initial={{ x: 10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                    transition={{ duration: 0.9, delay: 0.1, ease: "easeInOut" }}
                    className="earn-screen w-full h-full  py-5 px-[1.4rem]   ">
                    {/* <!-- Earn - Header --> */}
                    <div className="flex items-start justify-between">
                        <a href="#" onClick={props.earnBack} ref={earnPointsBackButtonRef} className="back-svg-btn back_btn_earn transition fast handler" href="#"></a>
                        <p className=" header-title-show font-semibold text-[16px] font-ubuntu self-center mb-[3px]"
                        >
                            Burn</p>
                        <a onClick={props.earnClosePopup} ref={earnPointsClosePopupRef} className="close-svg-btn close_popup close_btn_earn self-center" href="#">
                            <svg className="w-[20px] h-[20px] mb-[5px]" xmlns="http://www.w3.org/2000/svg" width="20"
                                height="20" viewBox="0 0 16 16" fill="#000">
                                <path fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z">
                                </path>
                                <path fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z">
                                </path>
                            </svg></a>
                    </div>
                    {/* Add Input form to verify the coupon the user enter */}
                    <div className="mt-[2rem]">
                        <form>
                            <div className="flex flex-col">
                                <input ref={burnPointsRef} onChange={(e) => setCoupon(e.target.value)} type="text" className={`mb-[12px] border rounded-md border-slate-200  ${error ? 'border-error' : ''} ${success ? 'border-success' : ''} py-2 px-4 outline-none focus:border-[#0071ce]`} placeholder='Enter your coupon number' />
                                {/* Feedback  */}
                                {error && <p ref={feedbackSpan} className='text-[16px] font-medium leading-3 text-red-500 '>
                                    {errorText}
                                </p>}

                                {success && <p span ref={feedbackSpan} className='text-[16px] font-medium leading-3 text-green-500 '>
                                    {successText}
                                </p>}

                                {/* if the coupon is valid then show it to the user with an option to copy it */}

                                {success && coupon &&
                                    <div className='w-[65%] mx-auto p-3 rounded-lg mt-[1.7rem] mb-[0.5rem] flex items-center justify-center gap-3'>
                                        <p className='font-bold text-black leading-4 text-[14px]'>
                                            {coupon}

                                        </p>

                                        <div className='group relative'>
                                            <svg
                                                className={`copySvgIcon cursor-pointer w-[16px] h-[16px]`}
                                                onClick={() => copyToClipboard(coupon)}
                                                viewBox="0 0 467 512.22" clipRule="evenodd"
                                                fillRule="evenodd"
                                                imageRendering="optimizeQuality"
                                                textRendering="geometricPrecision"
                                                shapeRendering="geometricPrecision"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#000" >

                                                <path d="M131.07 372.11c.37 1 .57 2.08.57 3.2 0 1.13-.2 2.21-.57 3.21v75.91c0 10.74 4.41 20.53 11.5 27.62s16.87 11.49 27.62 11.49h239.02c10.75 0 20.53-4.4 27.62-11.49s11.49-16.88 11.49-27.62V152.42c0-10.55-4.21-20.15-11.02-27.18l-.47-.43c-7.09-7.09-16.87-11.5-27.62-11.5H170.19c-10.75 0-20.53 4.41-27.62 11.5s-11.5 16.87-11.5 27.61v219.69zm-18.67 12.54H57.23c-15.82 0-30.1-6.58-40.45-17.11C6.41 356.97 0 342.4 0 326.52V57.79c0-15.86 6.5-30.3 16.97-40.78l.04-.04C27.51 6.49 41.94 0 57.79 0h243.63c15.87 0 30.3 6.51 40.77 16.98l.03.03c10.48 10.48 16.99 24.93 16.99 40.78v36.85h50c15.9 0 30.36 6.5 40.82 16.96l.54.58c10.15 10.44 16.43 24.66 16.43 40.24v302.01c0 15.9-6.5 30.36-16.96 40.82-10.47 10.47-24.93 16.97-40.83 16.97H170.19c-15.9 0-30.35-6.5-40.82-16.97-10.47-10.46-16.97-24.92-16.97-40.82v-69.78zM340.54 94.64V57.79c0-10.74-4.41-20.53-11.5-27.63-7.09-7.08-16.86-11.48-27.62-11.48H57.79c-10.78 0-20.56 4.38-27.62 11.45l-.04.04c-7.06 7.06-11.45 16.84-11.45 27.62v268.73c0 10.86 4.34 20.79 11.38 27.97 6.95 7.07 16.54 11.49 27.17 11.49h55.17V152.42c0-15.9 6.5-30.35 16.97-40.82 10.47-10.47 24.92-16.96 40.82-16.96h170.35z" fillRule="nonzero">
                                                </path>
                                            </svg>
                                            <span
                                                className="absolute top-[-2.5rem] right-[-1rem] scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                                {copied ? 'Copied' : 'Copy'}
                                            </span>
                                        </div>

                                    </div>

                                }



                                <button
                                    style={{
                                        backgroundColor: primaryColor
                                    }}
                                    disabled={isButtonDisabled}

                                    onClick={burnHandler} type="submit" className="w-[100%]  mt-[1rem]  p-2 text-white text-[16px] font-semibold rounded-md disabled:cursor-not-allowed disabled:opacity-50">
                                    Burn
                                </button>
                            </div>
                        </form>
                    </div>
                </m.div >

            </AnimatePresence >
        </>
    )
})

export default React.memo(Burn)
