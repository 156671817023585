import CryptoJS from "crypto-js";

const token_secret_key = '$2y$10$2Aggu5deacWhYT74vOEcHONgH.c.XEaZYePPWJ3.aXexEnt937rN2';
const token_secret_value = '$2y$10$HoligUPjFIzqjPSbV7Uq9.VojOmJ6TqVAbBR0RPSx5LVMaNiC.yYu';

const iv = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');

export const encrypt = (plainText, secretType) => {
    let secretKey = secretType === 'key' ? token_secret_key : token_secret_value;
    // Encryption
    const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(secretKey), {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

export const decrypt = (encryptedText, secretType) => {
    let secretKey = secretType === 'key' ? token_secret_key : token_secret_value;

    // Decryption
    const decrypted = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(secretKey), {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return decrypted;
}


