import React, { useEffect, useState, useRef } from "react";
import { BiAlarm } from "react-icons/bi";
import axiosInstance from "../../axios/axiosInstance";

const Counter = (props) => {
  const [seconds, setSeconds] = useState(60);

  const countdown = () => {
    setTimeout(() => setSeconds(seconds - 1), 1000);
  };

  const primaryColor = props?.primaryColor;
  useEffect(() => {
    if (seconds > 0) countdown();
    // if seconds is less than 10 then add a 0 in front of it
  }, [seconds]);

  const resentOtpHandler = (e) => {
    e.preventDefault();

    const header = {
      Accept: "application/json",
      "shop-domain": props.shopDomain,
    };
    const body = {
      WalletCode: props?.phoneNumberValueWithoutPlus,
      ContactMsisdn: props?.phoneNumberValueWithoutPlus,
    };
    axiosInstance
      .post("/api/v1/dsquares/points/otp", body, { headers: header })
      .then((response) => {
        setSeconds(60);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="w-full flex justify-between  mt-5">
        {seconds < 60 && seconds !== 0 ? (
          <button
            disabled
            className={`flex items-center disabled:cursor-not-allowed  disabled:text-${primaryColor} disabled:opacity-70 text-${primaryColor} cursor-pointer`}
          >
            <span
              style={{
                color: props?.primaryColor,
              }}
              className="font-medium text-[14px] "
            >
              Resend OTP
            </span>
          </button>
        ) : (
          <button
            style={{
              color: props?.primaryColor,
            }}
            onClick={resentOtpHandler}
            className={`flex items-center  hover:text-${primaryColor} cursor-pointer`}
          >
            <span className="font-medium text-[14px]">Resend OTP</span>
          </button>
        )}
        <div className="flex items-center justify-start gap-2">
          <BiAlarm type="solid" color="black" size="18px" />
          <span className="font-bold text-[14px]">
            00:{seconds.toString().padStart(2, "0")}
          </span>
        </div>
      </div>
    </>
  );
};

export default Counter;
