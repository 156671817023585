// import CryptoJS from "crypto-js";
import { decrypt, encrypt } from "./encryption";

class Token {
    static set(token) {
        let key = encrypt('token', process.env.TOKEN_SECRET_KEY);
        let value = encrypt(token, process.env.TOKEN_SECRET_VALUE);
        sessionStorage.setItem(key, value)
    }

    static get() {
        let key = encrypt('token', process.env.TOKEN_SECRET_KEY);
        let decrypted = decrypt(sessionStorage.getItem(key) ?? '', process.env.TOKEN_SECRET_VALUE);
        return decrypted?.toString();
    }
}

export default Token;
