// import axios from "axios";
// import config from "../config.json";
// import Token from "../encrypt/Token.js";
// import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";

// const BASE_API_URL = config.BASE_API_URL;
// const WIDGET_ACCESS_CLIENT_ID = config.WIDGET_ACCESS_CLIENT_ID;
// const WIDGET_ACCESS_CLIENT_SECRET = config.WIDGET_ACCESS_CLIENT_SECRET;

// const getToken = async () => {
//   try {
//     const response = await axios.post(
//       `${BASE_API_URL}/api/v1/auth/retrieve-token`,
//       {
//         client_id: WIDGET_ACCESS_CLIENT_ID,
//         client_secret: WIDGET_ACCESS_CLIENT_SECRET,
//         scope: "*",
//       }
//     );
//     Token?.set(response.data.data.access_token);
//     return response.data.data.access_token;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// const axiosInstance = axios.create({
//   baseURL: BASE_API_URL,
// });

// // Retry configuration
// axiosRetry(axiosInstance, {
//   retries: 3,
//   retryDelay: axiosRetry.exponentialDelay,
//   retryCondition: async (error) => {
//     const status = error?.response?.status;
//     const shouldRetry = isNetworkOrIdempotentRequestError(error) || status === 500;
//     // Check if this is a retry attempt and if it has exceeded the max retries
//     if (error.config && shouldRetry) {
//       error.config.__retryCount = error.config.__retryCount || 0;
//       if (error.config.__retryCount >= 3) {
//         return false; // Stop retrying after 3 attempts
//       }
//       if (status === 401) {
//         // Attempt to get a new token when a 401 error occurs
//         const newToken = await getToken();
//         if (newToken) {
//           error.config.headers.Authorization = `Bearer ${newToken}`;
//         }
//       }
//       error.config.__retryCount++;
//       return true; // Retry the request
//     }
//     return false;
//   },
// });

// axiosInstance.interceptors.request.use(
//   async (config) => {
//     const token = Token?.get();
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
import config from "../config.json";
import Cookies from "universal-cookie";
import Token from "../encrypt/Token.js";

const cookies = new Cookies();
const BASE_API_URL = config.BASE_API_URL;
const WIDGET_ACCESS_CLIENT_ID = config.WIDGET_ACCESS_CLIENT_ID;
const WIDGET_ACCESS_CLIENT_SECRET = config.WIDGET_ACCESS_CLIENT_SECRET;

const getToken = async () => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/api/v1/auth/retrieve-token`,
      {
        client_id: WIDGET_ACCESS_CLIENT_ID,
        client_secret: WIDGET_ACCESS_CLIENT_SECRET,
        scope: "*",
      }
    );
    Token?.set(response.data.data.access_token);
    return response.data.data.access_token;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = Token?.get();
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const MAX_RETRIES = 3;
    let retryCount = (error.config.__retryCount =
      (error.config.__retryCount || 0) + 1);

    if (error?.response?.status === 401 && retryCount < MAX_RETRIES) {
      retryCount++;
      error.config.__retryCount = retryCount;

      const newToken = await getToken();
      if (newToken) {
        error.config.headers["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(error.config);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
