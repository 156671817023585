import "./App.css";
import "./index.css";
import { useEffect, useState } from "react";
import Widget from "./widget/Widget.js";
import { AnimatePresence } from "framer-motion";
import axios from "axios";
import Cookies from "universal-cookie";
import Token from "./encrypt/Token.js";
import axiosInstance from "./axios/axiosInstance";
import config from "./config.json";

// import
function App() {
  const BASE_API_URL = config.BASE_API_URL;
  const WIDGET_ACCESS_CLIENT_ID = config.WIDGET_ACCESS_CLIENT_ID;
  const WIDGET_ACCESS_CLIENT_SECRET = config.WIDGET_ACCESS_CLIENT_SECRET;

  // const [customerID, setCustomerID] = useState(7185307795773);
  // const [shopDomain, setShopDomain] = useState('quick-start-36df4795.myshopify.com');

  const [customerID, setCustomerID] = useState();
  const [shopDomain, setShopDomain] = useState();

  console.log = function () {};

  const getToken = () => {
    axios
      .post(`${BASE_API_URL}/api/v1/auth/retrieve-token`, {
        client_id: WIDGET_ACCESS_CLIENT_ID,
        client_secret: WIDGET_ACCESS_CLIENT_SECRET,
        scope: "*",
      })
      .then((response) => {
        // cookies.set('token', response.data.data.access_token);
        Token.set(response.data.data.access_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    console.log("window location search ", window.location.search);
    // Get the query string from the URL
    var queryString = window.location.search;
    // Parse the query string to get an object of key-value pairs
    var params = new URLSearchParams(queryString);
    setCustomerID(params.get("customer_id"));
    setShopDomain(params.get("shop_domain"));
  }, []);

  return (
    <>
      <AnimatePresence>
        <Widget customerID={customerID} shopDomain={shopDomain} />
      </AnimatePresence>
    </>
  );
}

export default App;
