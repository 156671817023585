import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useImperativeHandle } from "react";
import { motion as m, AnimatePresence } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import axiosInstance from "../../axios/axiosInstance";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Ring } from "@uiball/loaders";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const History = React.forwardRef((props, ref) => {
  // load state so when i click on history button it will show the history screen
  // so i wanna load load state if the history is not empty
  const [loading, setLoading] = useState(false);
  // states
  const [userCoupons, setUserCoupons] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [copied, setCopied] = useState(false);
  const {
    historyBackButtonRef,
    historyClosePopupRef,
    primaryColor,
    customerID,
    shopDomain,
    phoneNumberValueWithoutPlus,
  } = props;
  const opacity = "0A";

  const copyToClipboard = async (couponNumber, index) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(couponNumber);
        setCopiedIndex(index);
        setCopied(true);
      } catch (err) {
        console.error("Unable to copy to clipboard using Clipboard API:", err);
        // Fallback to the document.execCommand method
        fallbackCopyTextToClipboard(couponNumber, index);
      }
    } else {
      // Fallback to the document.execCommand method
      fallbackCopyTextToClipboard(couponNumber, index);
    }
  };

  // Fallback function using document.execCommand
  const fallbackCopyTextToClipboard = (text, index) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopiedIndex(index);
      setCopied(true);
    } catch (err) {
      console.error(
        "Unable to copy to clipboard using document.execCommand:",
        err
      );
    } finally {
      document.body.removeChild(textArea);
    }
  };

  useImperativeHandle(ref, () => ({
    historyBackButtonRef,
    historyClosePopupRef,
  }));

  // function to convert date to readable format
  const convertDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const getHistory = () => {
    const header = {
      Accept: "application/json",
      "shop-domain": shopDomain,
    };
    const body = {
      customerMsisdn: phoneNumberValueWithoutPlus,
    };

    setLoading(true);
    axiosInstance
      .post("/api/v1/dsquares/coupon/history", body, { headers: header })
      .then((response) => {
        console.log("history res from histroy page", response);
        setUserCoupons(response.data.data.Data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // useEffect to get the coupons data
  useEffect(() => {
    getHistory();
  }, []);

  // function to check if the coupon is exipred or not based on the expiry date
  // const isExpired = (date) => {
  //     const newDate = new Date(date);
  //     const today = new Date();
  //     if (newDate < today) {
  //         return true;
  //     }
  //     else {
  //         return false;
  //     }
  // }

  function isExpired(expiryDate) {
    const currentDate = new Date();
    const couponExpiry = new Date(expiryDate);

    // Set the time to 12:00 AM on the next day
    couponExpiry.setHours(0, 0, 0, 0);
    couponExpiry.setDate(couponExpiry.getDate() + 1);

    return couponExpiry <= currentDate;
  }


  return (
    <>
      <AnimatePresence>
        <m.div
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.9, delay: 0.1, ease: "easeInOut" }}
          className="redeem-screen w-full h-full  overflow-hidden py-[4rem] "
        >
          {/* <!-- Redeem - Header --> */}
          <div className=" absolute w-full top-0 left-0 flex items-start justify-between pt-5 pb-3 border-b border-[#e1e1e1] px-[1.4rem]   ">
            <a
              onClick={props.historyBack}
              ref={historyBackButtonRef}
              className="back-svg-btn back_btn_redeem transition fast handler"
              href="#"
            ></a>
            <p className=" header-title-show font-semibold text-[16px] font-ubuntu self-center mb-[3px]">
              History
            </p>
            <a
              onClick={props.historyClosePopup}
              ref={historyClosePopupRef}
              className="close-svg-btn close_popup close_btn_redeem self-center"
              href="#"
            >
              <svg
                className="w-[20px] h-[20px] mb-[5px]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="#000"
              >
                <path
                  fillRule="evenodd"
                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                ></path>
              </svg>
            </a>
          </div>

          <div>
            {userCoupons?.length === 0 && !loading && (
              <div className="mt-[2rem] mb-[3rem] flex flex-col h-full w-full items-center justify-center">
                <svg
                  className="w-[12rem] h-[12rem] mt-[2.5rem]"
                  viewBox="0 0 304 283"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M141.5 283C219.648 283 283 219.648 283 141.5C283 63.3517 219.648 0 141.5 0C63.3517 0 0 63.3517 0 141.5C0 219.648 63.3517 283 141.5 283Z"
                    fill="#E6EBF3"
                  />
                  <mask
                    id="mask0_7_346"
                    maskUnits="userSpaceOnUse"
                    x="57"
                    y="120"
                    width="247"
                    height="133"
                  >
                    <path
                      d="M287.794 120.122H169.979C168.806 120.122 167.68 120.594 166.86 121.427L161.362 127.013C159.645 128.758 156.837 128.758 155.12 127.013L149.621 121.427C148.8 120.592 147.674 120.122 146.501 120.122H112.41C111.831 120.122 111.257 120.154 110.693 120.214H101.833C98.6199 120.214 95.7078 121.515 93.6013 123.624C91.4968 125.73 90.192 128.64 90.192 131.855C90.192 136.351 92.7426 140.254 96.4761 142.189C98.0795 143.022 99.9011 143.492 101.831 143.492H127.258C131.968 143.492 135.786 147.43 135.786 152.14C135.786 154.494 134.831 156.626 133.288 158.17C131.744 159.713 129.612 160.668 127.258 160.668H108.115C104.902 160.668 101.99 161.969 99.8834 164.077C97.7789 166.184 96.4741 169.092 96.4741 172.309C96.4741 178.736 101.687 183.949 108.115 183.949H136.248C143.08 183.949 148.621 189.489 148.621 196.323C148.621 199.74 147.236 202.833 144.996 205.071C142.758 207.311 139.663 208.696 136.248 208.696H66.4843C63.9337 208.696 61.6229 209.732 59.9507 211.402C58.2785 213.074 57.2429 215.387 57.2429 217.938C57.2429 223.043 61.3812 227.179 66.4843 227.179H132.204C135.218 227.179 137.666 229.624 137.666 232.642C137.666 234.149 137.053 235.515 136.065 236.503C135.079 237.49 133.711 238.103 132.204 238.103H118.417C116.389 238.103 114.55 238.926 113.222 240.256C111.893 241.585 111.07 243.424 111.07 245.452C111.07 249.51 114.36 252.799 118.417 252.799H146.501C147.676 252.799 148.802 252.327 149.621 251.494L155.12 245.908C156.837 244.163 159.645 244.163 161.362 245.908L166.86 251.494C167.682 252.329 168.808 252.799 169.979 252.799H287.794C296.596 252.799 303.729 245.666 303.729 236.867V136.054C303.729 127.255 296.596 120.122 287.794 120.122Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_7_346)">
                    <path
                      d="M303.727 136.054V236.867C303.727 245.666 296.594 252.799 287.794 252.799H169.979C168.806 252.799 167.68 252.327 166.858 251.492L161.358 245.904C159.643 244.161 156.833 244.161 155.118 245.904L149.618 251.492C148.796 252.327 147.67 252.799 146.497 252.799H112.406C103.607 252.799 96.4741 245.666 96.4741 236.867V136.054C96.4741 127.255 103.607 120.122 112.406 120.122H146.497C147.67 120.122 148.796 120.594 149.618 121.429L155.118 127.017C156.833 128.76 159.643 128.76 161.358 127.017L166.858 121.429C167.68 120.594 168.806 120.122 169.979 120.122H287.794C296.594 120.122 303.727 127.255 303.727 136.054Z"
                      style={{
                        fill: primaryColor,
                      }}
                    />
                    <path
                      d="M218.173 149.059C218.173 192.961 182.583 228.551 138.681 228.551C123.173 228.551 108.705 224.11 96.4766 216.431V136.054C96.4766 127.255 103.61 120.122 112.409 120.122H146.5C147.673 120.122 148.799 120.594 149.62 121.429L155.12 127.017C156.835 128.76 159.645 128.76 161.361 127.017L166.861 121.429C167.682 120.594 168.808 120.122 169.981 120.122H212.742C216.249 129.088 218.175 138.851 218.175 149.059H218.173Z"
                      fill={primaryColor}
                    />
                    <path
                      d="M161.213 140.252H154.351V150.77H161.213V140.252Z"
                      fill="white"
                    />
                    <path
                      d="M161.213 160.67H154.351V171.189H161.213V160.67Z"
                      fill="white"
                    />
                    <path
                      d="M161.213 181.086H154.351V191.605H161.213V181.086Z"
                      fill="white"
                    />
                    <path
                      d="M161.213 201.505H154.351V212.023H161.213V201.505Z"
                      fill="white"
                    />
                    <path
                      d="M161.213 221.923H154.351V232.442H161.213V221.923Z"
                      fill="white"
                    />
                    <path
                      d="M212.063 230.03C211.381 230.03 210.69 229.856 210.057 229.488C208.145 228.378 207.497 225.928 208.607 224.016L254.586 144.867C255.696 142.955 258.146 142.307 260.058 143.417C261.97 144.527 262.619 146.978 261.509 148.89L215.53 228.038C214.787 229.317 213.445 230.03 212.065 230.03H212.063Z"
                      fill="white"
                    />
                    <path
                      d="M207.526 188.019C202.635 188.019 197.898 186.447 193.915 183.46C189.051 179.811 185.899 174.488 185.041 168.469C184.182 162.45 185.719 156.457 189.366 151.594C193.015 146.73 198.338 143.578 204.357 142.72C216.779 140.947 228.332 149.611 230.106 162.036C231.878 174.46 223.213 186.011 210.79 187.785C209.699 187.94 208.609 188.017 207.526 188.017V188.019ZM207.583 150.497C206.891 150.497 206.192 150.546 205.488 150.646C201.586 151.203 198.135 153.246 195.772 156.398C193.406 159.55 192.411 163.435 192.967 167.337C193.524 171.24 195.567 174.69 198.719 177.054C201.871 179.42 205.756 180.414 209.658 179.858C217.713 178.709 223.331 171.222 222.181 163.167C221.132 155.816 214.805 150.497 207.583 150.497Z"
                      fill="white"
                    />
                    <path
                      d="M262.491 230.418C257.6 230.418 252.863 228.846 248.88 225.859C244.016 222.21 240.864 216.887 240.006 210.868C238.233 198.443 246.899 186.893 259.322 185.118C265.34 184.26 271.334 185.796 276.197 189.443C281.06 193.092 284.212 198.416 285.071 204.434C285.93 210.453 284.393 216.446 280.746 221.31C277.097 226.173 271.774 229.325 265.755 230.184C264.664 230.339 263.574 230.416 262.491 230.416V230.418ZM262.57 192.894C261.868 192.894 261.161 192.943 260.453 193.045C252.399 194.195 246.783 201.683 247.93 209.736C248.487 213.638 250.53 217.089 253.682 219.453C256.834 221.819 260.719 222.815 264.621 222.257C268.524 221.701 271.974 219.657 274.338 216.505C276.704 213.353 277.698 209.469 277.142 205.566C276.586 201.664 274.542 198.213 271.391 195.847C268.809 193.912 265.737 192.892 262.568 192.892L262.57 192.894Z"
                      fill="white"
                    />
                    <path
                      d="M109.854 120.122H79.0308V145.512H109.854V120.122Z"
                      fill={primaryColor}
                    />
                    <path
                      d="M111.182 205.686H49.5361V232.257H111.182V205.686Z"
                      style={{
                        fill: primaryColor,
                      }}
                    />
                    <path
                      d="M111.182 205.686V223.544C100.626 219.604 91.1391 213.466 83.2771 205.686H111.182Z"
                      fill={primaryColor}
                    />
                  </g>
                  <path
                    d="M80.5223 172.311C80.5223 165.882 75.311 160.67 68.8826 160.67C62.4542 160.67 57.2429 165.882 57.2429 172.311C57.2429 178.74 62.4542 183.951 68.8826 183.951C75.311 183.951 80.5223 178.74 80.5223 172.311Z"
                    fill={primaryColor}
                  />
                  <path
                    d="M95.2185 245.451C95.2185 241.393 91.9286 238.103 87.8703 238.103C83.8121 238.103 80.5222 241.393 80.5222 245.451C80.5222 249.509 83.8121 252.799 87.8703 252.799C91.9286 252.799 95.2185 249.509 95.2185 245.451Z"
                    style={{
                      fill: primaryColor,
                    }}
                  />
                  <path
                    d="M161.869 33.8056H125.949C117.942 33.8056 111.451 40.2965 111.451 48.3034V84.2236C111.451 92.2305 117.942 98.7213 125.949 98.7213H161.869C169.876 98.7213 176.367 92.2305 176.367 84.2236V48.3034C176.367 40.2965 169.876 33.8056 161.869 33.8056Z"
                    fill="white"
                  />
                  <path
                    d="M161.869 98.7213V107.959L148.657 98.7213H161.869Z"
                    fill="white"
                  />
                  <path
                    d="M149.918 66.2634L160.924 55.2575C162.583 53.599 162.583 50.907 160.924 49.2485C159.266 47.5901 156.574 47.5901 154.915 49.2485L143.909 60.2545L132.903 49.2485C131.245 47.5901 128.553 47.5901 126.894 49.2485C125.236 50.907 125.236 53.599 126.894 55.2575L137.9 66.2634L126.894 77.2694C125.236 78.9279 125.236 81.6199 126.894 83.2784C127.723 84.1076 128.812 84.5222 129.899 84.5222C130.985 84.5222 132.074 84.1076 132.903 83.2784L143.909 72.2724L154.915 83.2784C155.744 84.1076 156.833 84.5222 157.92 84.5222C159.006 84.5222 160.095 84.1076 160.924 83.2784C162.583 81.6199 162.583 78.9279 160.924 77.2694L149.918 66.2634Z"
                    fill="#FF3342"
                  />
                  <path
                    d="M65.3388 79.1283C67.0458 79.1283 68.4297 77.7444 68.4297 76.0374C68.4297 74.3303 67.0458 72.9464 65.3388 72.9464C63.6317 72.9464 62.2478 74.3303 62.2478 76.0374C62.2478 77.7444 63.6317 79.1283 65.3388 79.1283Z"
                    style={{
                      fill: primaryColor,
                    }}
                  />
                  <path
                    d="M39.2691 281.907C42.1765 281.907 44.5334 279.55 44.5334 276.642C44.5334 273.735 42.1765 271.378 39.2691 271.378C36.3618 271.378 34.0049 273.735 34.0049 276.642C34.0049 279.55 36.3618 281.907 39.2691 281.907Z"
                    fill="#E6EBF3"
                  />
                  <path
                    d="M288.72 75.6527C289.289 72.1445 286.907 68.8388 283.399 68.2694C279.891 67.6999 276.585 70.0822 276.015 73.5905C275.446 77.0987 277.828 80.4044 281.337 80.9738C284.845 81.5433 288.15 79.161 288.72 75.6527Z"
                    style={{
                      fill: primaryColor,
                    }}
                  />
                </svg>

                <p
                  style={{
                    color: primaryColor,
                  }}
                  className=" text-[18px] my-[12px] font-bold leading-4"
                >
                  No coupons yet!
                </p>
              </div>
            )}

            {userCoupons?.length > 0 && !loading && (
              <div className="ds-history pt-[1.5rem] h-[40rem]  mt-[0.5rem] md:h-[35rem] mb-[3rem]  w-full px-[1.4rem] overflow-y-auto ">
                <Accordion allowZeroExpanded>
                  {userCoupons.map((coupon, i) => (
                    <AccordionItem
                      className="accordionItem"
                      key={coupon.CouponNumber}
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h5
                            style={{
                              color: primaryColor,
                            }}
                            className="accordionHeader"
                          >
                            {/* {coupon.CouponNumber} */}
                            {coupon.Value} EGP Coupon
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="flex flex-col">
                          <div
                            style={{
                              backgroundColor: `${primaryColor}${opacity}`,
                            }}
                            className="p-[20px] flex items-start justify-between"
                          >
                            <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                              Coupon Details
                            </h6>
                            <p className="font-bold text-black leading-4 text-[14px]">
                            {coupon.Value} EGP
                            </p>
                          </div>

                          <div className="p-[20px] flex items-start justify-between">
                            <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                            Coupon Code
                            </h6>
                            <div className="flex gap-2">
                              <p className="font-bold text-black leading-4 text-[14px]">
                                {coupon.CouponNumber}
                              </p>

                              <div className="group relative">
                                <svg
                                  className={`copySvgIcon cursor-pointer w-[16px] h-[16px]`}
                                  onClick={() =>
                                    copyToClipboard(coupon.CouponNumber, i)
                                  }
                                  viewBox="0 0 467 512.22"
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                  imageRendering="optimizeQuality"
                                  textRendering="geometricPrecision"
                                  shapeRendering="geometricPrecision"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#000"
                                >
                                  <path
                                    d="M131.07 372.11c.37 1 .57 2.08.57 3.2 0 1.13-.2 2.21-.57 3.21v75.91c0 10.74 4.41 20.53 11.5 27.62s16.87 11.49 27.62 11.49h239.02c10.75 0 20.53-4.4 27.62-11.49s11.49-16.88 11.49-27.62V152.42c0-10.55-4.21-20.15-11.02-27.18l-.47-.43c-7.09-7.09-16.87-11.5-27.62-11.5H170.19c-10.75 0-20.53 4.41-27.62 11.5s-11.5 16.87-11.5 27.61v219.69zm-18.67 12.54H57.23c-15.82 0-30.1-6.58-40.45-17.11C6.41 356.97 0 342.4 0 326.52V57.79c0-15.86 6.5-30.3 16.97-40.78l.04-.04C27.51 6.49 41.94 0 57.79 0h243.63c15.87 0 30.3 6.51 40.77 16.98l.03.03c10.48 10.48 16.99 24.93 16.99 40.78v36.85h50c15.9 0 30.36 6.5 40.82 16.96l.54.58c10.15 10.44 16.43 24.66 16.43 40.24v302.01c0 15.9-6.5 30.36-16.96 40.82-10.47 10.47-24.93 16.97-40.83 16.97H170.19c-15.9 0-30.35-6.5-40.82-16.97-10.47-10.46-16.97-24.92-16.97-40.82v-69.78zM340.54 94.64V57.79c0-10.74-4.41-20.53-11.5-27.63-7.09-7.08-16.86-11.48-27.62-11.48H57.79c-10.78 0-20.56 4.38-27.62 11.45l-.04.04c-7.06 7.06-11.45 16.84-11.45 27.62v268.73c0 10.86 4.34 20.79 11.38 27.97 6.95 7.07 16.54 11.49 27.17 11.49h55.17V152.42c0-15.9 6.5-30.35 16.97-40.82 10.47-10.47 24.92-16.96 40.82-16.96h170.35z"
                                    fillRule="nonzero"
                                  ></path>
                                </svg>
                                <span className="absolute top-[-2.5rem] right-[-1rem] scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                  {copiedIndex === i && copied
                                    ? "Copied"
                                    : "Copy"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              backgroundColor: `${primaryColor}${opacity}`,
                            }}
                            className="p-[20px]  flex items-start justify-between"
                          >
                            <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                              Expiry Date
                            </h6>
                            <p className="font-bold text-black leading-4 text-[14px]">
                              {convertDate(coupon.ExpiryDate)}
                            </p>
                          </div>
                          <div className="p-[20px] flex items-start justify-between">
                            <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                              Status
                            </h6>
                            <p
                              className={`font-bold ${
                                coupon.Status === "Burned" ||
                                coupon.Status === "Expired" ||
                                coupon.Status === "Cancelled"
                                  ? "text-red-500"
                                  : "text-[#1FB981]"
                              }  leading-4 text-[14px]`}
                            >
                              {coupon.Status}
                            </p>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            )}
          </div>

          {/* {(loading) &&
                        <div className="h-[90%] mb-[3rem] flex items-center justify-center">
                            <Ring color={primaryColor} size={50} />
                        </div>}
                         */}
        </m.div>
      </AnimatePresence>
    </>
  );
});

export default React.memo(History);
