import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useImperativeHandle } from "react";
import { motion as m, AnimatePresence } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import axiosInstance from "../../axios/axiosInstance";
import Counter from "../counter/Counter";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { Ring } from "@uiball/loaders";

const Redeem = React.forwardRef((props, ref) => {
  // states
  const [userCoupons, setUserCoupons] = useState([]);

  const [summary, setSummary] = useState();
  const [updatedTotalPoints, setUpdatedTotalPoints] = useState();
  const [totalPointsLoading, setTotalPointsLoading] = useState(false);
  const [redeemedSuccessfully, setRedeemedSuccessfully] = useState(false);

  const [discountCodeData, setDiscountCodeData] = useState(null);
  const [couponCode, setCouponCode] = useState(null);

  const [showOTP, setShowOTP] = useState(false);
  const [pointsInputValue, setPointsInputValue] = useState(null);
  const [pointsInputError, setPointsInputError] = useState(false);
  const [pointsInputErrorMsg, setPointsInputErrorMsg] = useState(null);
  const [encryptedPhoneNumber, setEncryptedPhoneNumber] = useState();

  const [otpValues, setOtpValues] = useState(Array(6).fill("")); // Initialize state for 6 OTP digits

  const [isThereOtpResponse, setIsThereOtpResponse] = useState(false);

  const [copiedIndex, setCopiedIndex] = useState(null);
  const [textCopied, setTextCopied] = useState(false);

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  // redeem error states
  const [isRedeemError, setIsRedeemError] = useState(false);
  const [redeemErrorMsg, setRedeemErrorMsg] = useState("");

  // refs
  const pointsRef = useRef();

  const opacity = "0A";

  const {
    redeemPointsBackButtonRef,
    redeemPointsClosePopupRef,
    refetchSummary,
    primaryColor,
    customerID,
    shopDomain,
    phoneNumberValueWithoutPlus,
  } = props;

  useImperativeHandle(ref, () => ({
    redeemPointsBackButtonRef,
    redeemPointsClosePopupRef,
  }));

  const getHistory = () => {
    const header = {
      Accept: "application/json",
      "shop-domain": shopDomain,
    };
    const body = {
      customerMsisdn: phoneNumberValueWithoutPlus,
    };

    axiosInstance
      .post("/api/v1/dsquares/coupon/history", body, { headers: header })
      .then((response) => {
        setUserCoupons(response.data.data.Data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const copyToClipboardCode = () => {
    if (navigator.clipboard) {
      // Use Clipboard API if available
      try {
        navigator.clipboard
          .writeText(couponCode)
          .then(() => {
            setTextCopied(true);
            console.log("Text copied to clipboard navigator API :", couponCode);
          })
          .catch((err) => {
            console.error(
              "Error copying to clipboard using Clipboard API navigator API:",
              err
            );
            fallbackCopyTextToClipboardTextOnly(couponCode);
          });
      } catch (err) {
        console.error(
          "Unable to copy to clipboard using Clipboard API navigator API:",
          err
        );
        fallbackCopyTextToClipboardTextOnly(couponCode);
      }
    } else {
      // Fallback to document.execCommand for older browsers
      fallbackCopyTextToClipboardTextOnly(couponCode);
    }
  };
  // Fallback function using document.execCommand and pass text only as a parameter
  const fallbackCopyTextToClipboardTextOnly = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setTextCopied(true);
      console.log(
        "Text copied to clipboard fallBackCopyText execCommand :",
        text
      );
    } catch (err) {
      console.error(
        "Unable to copy to clipboard using document.execCommand:",
        err
      );
    } finally {
      document.body.removeChild(textArea);
    }
  };

  const copyToClipboard = async (couponNumber, index) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(couponNumber);
        setCopiedIndex(index);
        setTextCopied(true);
      } catch (err) {
        console.error("Unable to copy to clipboard using Clipboard API:", err);
        // Fallback to the document.execCommand method
        fallbackCopyTextToClipboard(couponNumber, index);
      }
    } else {
      // Fallback to the document.execCommand method
      fallbackCopyTextToClipboard(couponNumber, index);
    }
  };

  // Fallback function using document.execCommand
  const fallbackCopyTextToClipboard = (text, index) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopiedIndex(index);
      setTextCopied(true);
    } catch (err) {
      console.error(
        "Unable to copy to clipboard using document.execCommand:",
        err
      );
    } finally {
      document.body.removeChild(textArea);
    }
  };

  const handleOTPCKeyDown = (index, value, event) => {
    if (event.key === "Backspace" && value === "") {
      if (index > 0) {
        // Go to the previous input
        document.querySelectorAll("input")[index - 1].focus();
      }
    }
    // else if (value === '') {}
  };

  const handleSubmitRequest = () => {
    try {
      const header = {
        Accept: "application/json",
        "shop-domain": shopDomain,
      };
      console.log(otpValues);
      const body = {
        PlatformCustomerId: customerID.toString(),
        Points: pointsInputValue,
        ContactMsisdn: phoneNumberValueWithoutPlus,
        WalletCode: phoneNumberValueWithoutPlus,
        PinCode: otpValues.join(""),
      };
      axiosInstance
        .post("api/v1/dsquares/points/redeem", body, { headers: header })
        .then((response) => {
          console.log(response);
          refetchSummary();
          setRedeemedSuccessfully(true);
          setIsRedeemError(false);
          setRedeemErrorMsg(null);
          setShowOTP(false);
          setUpdatedTotalPoints(summary.totalPoints - pointsInputValue);
          setDiscountCodeData(response.data.data.discount_code);
          setCouponCode(response.data.data.discount_code.code);

          setOtpValues(otpValues.fill(""));
          getHistory();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data.error);
          setRedeemedSuccessfully(false);
          setIsRedeemError(true);
          setRedeemErrorMsg(error.response.data.error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOTPChange = (index, value) => {
    // Create a new array with the updated value at the specified index
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;
    setOtpValues(updatedOtpValues);

    // i wanna when every input has a value then go automatically to the next input
    if (value !== "") {
      if (index < otpValues.length - 1) {
        document.querySelectorAll("input")[index + 1].focus();
      }
    }

    // // if all the otp fields are filled then call redeem points api
    // if (updatedOtpValues.every((val) => val !== '')) {
    //     const header = {
    //         'Accept': 'application/json',
    //         'shop-domain': shopDomain

    //     }
    //     console.log(otpValues)
    //     const body = {
    //         "PlatformCustomerId": customerID.toString(),
    //         "Points": pointsInputValue,
    //         "ContactMsisdn": phoneNumberValueWithoutPlus,
    //         "WalletCode": phoneNumberValueWithoutPlus,
    //         "PinCode": otpValues.join(''),
    //     }
    //     axiosInstance.post('api/v1/dsquares/points/redeem', body, { headers: header })
    //         .then((response) => {
    //             console.log(response);
    //             refetchSummary();
    //             setRedeemedSuccessfully(true);
    //             setIsRedeemError(false);
    //             setRedeemErrorMsg(null);
    //             setShowOTP(false);
    //             setUpdatedTotalPoints(summary.totalPoints - pointsInputValue);
    //             setDiscountCodeData(response.data.data.discount_code);
    //             setCouponCode(response.data.data.discount_code.code);

    //             setOtpValues(otpValues.fill(''));
    //             getHistory();
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             console.log(error.response.data.error);
    //             setRedeemedSuccessfully(false);
    //             setIsRedeemError(true);
    //             setRedeemErrorMsg(error.response.data.error);
    //         })

    // }
  };

  useEffect(() => {
    const isCompletedOTP = otpValues.every((val) => val !== "");
    if (isCompletedOTP) {
      handleSubmitRequest();
    }
  }, [otpValues]);

  // useEffect to get Summary
  useEffect(() => {
    setTotalPointsLoading(true);

    const header = {
      Accept: "application/json",
      "shop-domain": shopDomain,
    };

    const body = {
      walletCode: phoneNumberValueWithoutPlus,
    };

    axiosInstance
      .post("/api/v1/dsquares/wallet/summary", body, { headers: header })
      .then((response) => {
        console.log(response.data.data.data.totalPoints);
        setSummary(response.data.data.data);
        setUpdatedTotalPoints(response.data.data.data.totalPoints);

        // encrypt contact number
        const contactNumber = response.data.data.data.contactMsisdn;
        setEncryptedPhoneNumber(
          contactNumber.substring(0, 2) +
            contactNumber.substring(2, 9).replace(/[0-9]/g, "*") +
            contactNumber.substring(9, 11)
        );
      })
      .catch((error) => {
        console.log(error);
        // if the error is 500 then show the error msg
        if (error?.response?.status === 500) {
          setIsRedeemError(true);
          setUpdatedTotalPoints(0);
          setRedeemErrorMsg(
            error.response.data.error ||
              "Something went wrong, please try again later"
          );
        }
        // if cant fetch updated total points then show 0
        setUpdatedTotalPoints(0);
      })
      .finally(() => {
        setTotalPointsLoading(false);
      });
  }, [summary?.totalPoints]);

  // useEffect to get the coupons data
  useEffect(() => {
    getHistory();
  }, []);

  // function to convert date to readable format
  const convertDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const redeemHandler = (e) => {
    e.preventDefault();
    setButtonDisabled(true); // Disable the button

    // first if redeem points input is empty or null show error msg
    if (!pointsInputValue) {
      setPointsInputError(true);
      setPointsInputErrorMsg("Please Enter Points to Redeem ");
      // set timeout to enable the button after 3 seconds
      setTimeout(() => {
        setButtonDisabled(false);
      }, [500]); // enable the button
      return;
    }
    // redeem points  should not be 0 or less  or greater than total points
    else if (pointsInputValue <= 0 || pointsInputValue > summary?.totalPoints) {
      setPointsInputError(true);
      setPointsInputErrorMsg("Please Enter Valid Points to Redeem");
      // set timeout to enable the button after 3 seconds
      setTimeout(() => {
        setButtonDisabled(false);
      }, [500]); // enable the button
      return;
    }
    // redeem points should be greater than 33
    else if (pointsInputValue < 33) {
      setPointsInputError(true);
      setPointsInputErrorMsg("The minimum amount to be redeemed is 33 Points.");
      // set timeout to enable the button after 3 seconds
      setTimeout(() => {
        setButtonDisabled(false);
      }, [500]); // enable the button
      return;
    }
    // points should be integer not float
    else if (pointsInputValue % 1 !== 0) {
      setPointsInputError(true);
      setPointsInputErrorMsg(
        "Points should be integer. Please enter a valid points value."
      );
      // set timeout to enable the button after 3 seconds
      setTimeout(() => {
        setButtonDisabled(false);
      }, [500]); // enable the button
      return;
    } else {
      setPointsInputError(false);
      setPointsInputErrorMsg(null);
      // Call OTP endpoint
      const header = {
        Accept: "application/json",
        "shop-domain": shopDomain,
      };
      const body = {
        WalletCode: phoneNumberValueWithoutPlus,
        ContactMsisdn: phoneNumberValueWithoutPlus,
      };
      axiosInstance
        .post("/api/v1/dsquares/points/otp", body, { headers: header })
        .then((response) => {
          setShowOTP(true);
          // console.log('OTP RES', response);
          pointsRef.current.value = "";
          setIsThereOtpResponse(true);
        })
        .catch((error) => {
          console.log(error);
          setRedeemedSuccessfully(false);
        })
        .finally(() => {
          pointsRef.current.value = "";
          setButtonDisabled(false);
        });
    }
  };

  const activeTabStyles = {
    backgroundColor: primaryColor,
    color: "#fff",
    borderRadius: "0",
  };
  const [isActiveTab1, setIsActiveTab1] = useState(true);
  const [isActiveTab2, setIsActiveTab2] = useState(false);
  const hanldeActiveTab1 = () => {
    setIsActiveTab1(true);
    setIsActiveTab2(false);
  };
  const hanldeActiveTab2 = () => {
    setIsActiveTab1(false);
    setIsActiveTab2(true);
  };

  function isExpired(expiryDate) {
    const currentDate = new Date();
    const couponExpiry = new Date(expiryDate);

    // Set the time to 12:00 AM on the next day
    couponExpiry.setHours(0, 0, 0, 0);
    couponExpiry.setDate(couponExpiry.getDate() + 1);

    return couponExpiry <= currentDate;
  }

  return (
    <>
      <AnimatePresence>
        <m.div
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.9, delay: 0.1, ease: "easeInOut" }}
          className="relative redeem-screen w-full h-full      "
        >
          {/* <!-- Redeem - Header --> */}
          <div className="absolute bg-white left-0  border-b border-[#e1e1e1] w-full top-0 z-[99999] flex items-start justify-between py-3 px-[1.4rem] overflow-hidden">
            <a
              onClick={props.redeemBack}
              ref={redeemPointsBackButtonRef}
              className="back-svg-btn back_btn_redeem transition fast handler"
              href="#"
            ></a>
            <p className=" header-title-show font-semibold text-[16px] font-ubuntu self-center mb-[3px]">
              Redeem
            </p>
            <a
              onClick={props.redeemClosePopup}
              ref={redeemPointsClosePopupRef}
              className="close-svg-btn close_popup close_btn_redeem self-center"
              href="#"
            >
              <svg
                className="w-[20px] h-[20px] mb-[5px]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="#000"
              >
                <path
                  fillRule="evenodd"
                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                ></path>
              </svg>
            </a>
          </div>

          {/* <!-- Tabs --> */}
          <Tabs className="pt-[6rem] px-[1.4rem] h-full md:h-[38rem] overflow-y-auto ">
            <TabList className="absolute w-[90%] md:w-[85%] flex justify-center items-center ">
              <Tab
                style={{
                  backgroundColor: `${primaryColor}${opacity}`,
                  ...(isActiveTab1 ? activeTabStyles : {}),
                }}
                aria-selected={isActiveTab1}
                onClick={hanldeActiveTab1}
                // style={{
                //   backgroundColor:`${primaryColor}${opacity}`
                // }}
                className={`tabItem w-full cursor-pointer`}
              >
                Redeem
              </Tab>
              <Tab
                style={{
                  backgroundColor: `${primaryColor}${opacity}`,
                  ...(isActiveTab2 ? activeTabStyles : {}),
                }}
                aria-selected={isActiveTab2}
                onClick={hanldeActiveTab2}
                className="tabItem w-full cursor-pointer"
              >
                Coupons
              </Tab>
            </TabList>

            {/* Redeem Panel */}
            <TabPanel className="mt-[5rem]">
              <h5
                style={{
                  color: primaryColor,
                }}
                className="text-[16px] font-medium mt-[2rem]  mb-[1.2rem] leading-4"
              >
                Create Discount Coupon
              </h5>

              <div className="p-[20px] bg-white rounded-xl ">
                <div className="flex items-start justify-start gap-2 mb-[8px] w-full">
                  <div className="w-[35px] h-[35px]">
                    <img
                      className="w-[92%]"
                      alt="points icon"
                      src="https://assets.gameball.co/widget/img/coupon/coupon_fixed.svg"
                    />
                  </div>

                  <p className="flex items-start justify-start gap-1 text-[14px] font-semibold leading-4 self-center">
                    Your Points balance is &nbsp;
                    <span
                      style={{
                        color: primaryColor,
                      }}
                      className="font-bold "
                    >
                      {/* if there is total points show it if not yet then show loader */}
                      {/* {totalPointsLoading ? (
                                                <Ring color={primaryColor} size={20} />
                                            ) : (

                                                updatedTotalPoints && !totalPointsLoading && (
                                                    updatedTotalPoints.toLocaleString()
                                                )
                                            )} */}

                      {totalPointsLoading ? (
                        <Ring color={primaryColor} size={20} />
                      ) : (
                        <span>
                          {updatedTotalPoints !== undefined &&
                          updatedTotalPoints > 0
                            ? updatedTotalPoints.toLocaleString()
                            : "0"}
                        </span>
                      )}
                      {/* {updatedTotalPoints ? updatedTotalPoints?.toLocaleString() : <Ring color={primaryColor} size={20} />} */}
                      {/* {updatedTotalPoints?.toLocaleString()} */}
                    </span>
                  </p>
                </div>

                {/* Redeem */}
                {!showOTP && !redeemedSuccessfully && (
                  <div className="mt-[1rem] transition-all duration-300 ">
                    <form>
                      <div className="flex items-start justify-center gap-3">
                        <input
                          ref={pointsRef}
                          onChange={(e) => setPointsInputValue(e.target.value)}
                          type="number"
                          step="1"
                          min="1"
                          className={` ${
                            pointsInputErrorMsg
                              ? "border border-red-500 bg-red-100"
                              : "border border-[#f0f0f0]"
                          } input_Number `}
                          placeholder="0"
                        />
                        <button
                          style={{
                            backgroundColor: primaryColor,
                          }}
                          type="submit"
                          className="submitRedeemBtn disabled:cursor-not-allowed disabled:opacity-50"
                          disabled={isButtonDisabled}
                          onClick={redeemHandler}
                        >
                          Redeem
                        </button>
                      </div>
                      {!pointsInputError && !redeemedSuccessfully && (
                        <p className="text-[12px] leading-4 mt-[1.2rem] font-bold ">
                          The minimum amount to be redeemed is 33 Points.
                        </p>
                      )}
                      {pointsInputError && !redeemedSuccessfully && (
                        <p className="text-[12px] leading-4 mt-[1.2rem] font-bold text-red-600">
                          {pointsInputErrorMsg}
                        </p>
                      )}
                      {redeemedSuccessfully && (
                        <p className="text-[12px] leading-4 mt-[1.2rem] font-bold text-green-600">
                          Points Redeemed Successfully
                        </p>
                      )}
                    </form>
                  </div>
                )}
                {/* OTP */}
                {showOTP && !redeemedSuccessfully && (
                  <m.div
                    // initial={{ opacity: 0 }}
                    // animate={{ opacity: 1 }}
                    // exit={{ opacity: 0 }}
                    // transition={{ duration: 0.9, delay: 0.1, ease: "easeInOut" }}
                    className="container mx-auto transition-all duration-300 "
                  >
                    <div className="w-full">
                      <div className="w-full">
                        <div className="bg-white h-64 py-3 rounded text-center">
                          <h1 className="text-2xl font-bold">
                            OTP Verification
                          </h1>
                          <div className="flex flex-col mt-4">
                            <span>Enter the OTP you received at</span>
                            <span className="font-bold">
                              +{encryptedPhoneNumber}
                            </span>
                          </div>

                          <div
                            id="otp"
                            className="flex flex-row justify-center text-center px-2 mt-5"
                          >
                            {otpValues.map((value, index) => (
                              <input
                                key={index}
                                className={` m-2 border ${
                                  isRedeemError ? "border-red-500" : ""
                                } h-10 w-10 text-center focus:outline-none focus-visible:outline-none form-control rounded`}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) =>
                                  handleOTPChange(index, e.target.value)
                                }
                                onKeyDown={(e) =>
                                  handleOTPCKeyDown(index, e.target.value, e)
                                }
                              />
                            ))}
                          </div>
                          {isRedeemError && (
                            <p className="mb-2 text-start text-[14px] tracking-[0.25px] leading-5 font-medium text-red-600">
                              {redeemErrorMsg}
                            </p>
                          )}

                          <div className="flex justify-between  mt-5">
                            {isThereOtpResponse && (
                              <Counter
                                walletCode={summary?.walletCode}
                                contactMsisdn={summary?.contactMsisdn}
                                phoneNumberValueWithoutPlus={
                                  phoneNumberValueWithoutPlus
                                }
                                customerID={props.customerID}
                                shopDomain={props.shopDomain}
                                primaryColor={primaryColor}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </m.div>
                )}

                {/* feedback if otp created successfully */}
                {redeemedSuccessfully && !showOTP && (
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.9,
                      delay: 0.1,
                      ease: "easeInOut",
                    }}
                    className=" overflow-y-auto overflow-x-hidden  justify-center items-center w-full  h-modal md:h-full"
                  >
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                      {/* <!-- Modal content --> */}
                      <div className="relative p-4 text-center bg-white  sm:p-5">
                        <div className=" w-[12rem] h-[8rem] rounded-full p-2 flex items-center justify-center mx-auto mb-3.5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="421"
                            height="269"
                            fill="none"
                            viewBox="0 0 421 269"
                          >
                            <path
                              style={{
                                fill: primaryColor,
                              }}
                              fillRule="evenodd"
                              d="M43.5 7.5v5h11c1.673.843 2.84 2.176 3.5 4a133.837 133.837 0 002.5 12c-4.359-6.265-10.025-7.598-17-4a86.401 86.401 0 01-7.5 10 32.861 32.861 0 00.5 11c.137 2.34-.53 4.34-2 6-1.291.237-2.291-.096-3-1 .912-1.238 1.079-2.572.5-4-11.59-8.92-13.423-19.42-5.5-31.5C22.542 6.277 25.375 1.444 35 .5c4.769-.067 7.603 2.266 8.5 7z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#CECFD1"
                              fillRule="evenodd"
                              d="M419.5 4.5c.904.709 1.237 1.709 1 3a6644.123 6644.123 0 00-163 1c-.543-.06-.876-.393-1-1a6600.456 6600.456 0 00163-3z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#686775"
                              fillRule="evenodd"
                              d="M43.5 7.5c.934 1.068 1.268 2.401 1 4 3.542-.313 6.875.021 10 1h-11v-5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#EDB5B4"
                              fillRule="evenodd"
                              d="M60.5 28.5c-.463 7.602-3.963 13.268-10.5 17a8.43 8.43 0 00-.5 4c-4.979 2.113-9.979 2.78-15 2 1.47-1.659 2.137-3.659 2-6a32.861 32.861 0 01-.5-11 86.401 86.401 0 007.5-10c6.975-3.598 12.641-2.265 17 4z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              style={{
                                fill: primaryColor,
                              }}
                              fillRule="evenodd"
                              d="M298.5 34.5c13.039-1.9 22.206 3.1 27.5 15 2.161 13.183-3.005 22.017-15.5 26.5-13.008 2.336-21.508-2.83-25.5-15.5-2.076-12.184 2.424-20.85 13.5-26z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#CDCDCD"
                              fillRule="evenodd"
                              d="M344.5 40.5a840.27 840.27 0 0141 .5c2.703 1.82 3.536 4.32 2.5 7.5a9.454 9.454 0 01-2.5 1.5c-14 .667-28 .667-42 0-2.825-3.524-2.492-6.69 1-9.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#EFF2F9"
                              fillRule="evenodd"
                              d="M312.5 45.5h4a8.43 8.43 0 01-.5 4 78.664 78.664 0 00-9 12c-3.738 1.233-6.905.233-9.5-3-1.502-2.333-1.002-3.999 1.5-5 1.7 1.427 3.533 2.26 5.5 2.5a117.686 117.686 0 008-10.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#E6E5E6"
                              fillRule="evenodd"
                              d="M49.5 49.5c8.538 1.959 16.538 5.293 24 10a286.05 286.05 0 0113.5 29 592.728 592.728 0 0118.5 23c-2.295.636-3.961 1.969-5 4-10.426-7.757-19.092-17.09-26-28a3.647 3.647 0 00-1.5 1 768.085 768.085 0 00-2.5 22c-1.322-2.97-3.656-4.303-7-4a429.333 429.333 0 00-18 4c-.843-1.673-2.176-2.84-4-3.5a436.267 436.267 0 00-11-1.5v7h-2a213.67 213.67 0 01-23-5.5c-2.914-1.909-4.581-4.575-5-8A483.567 483.567 0 0114 66.5c6.521-4.53 13.021-9.03 19.5-13.5a6.802 6.802 0 01-3-1.5c.124-.607.457-.94 1-1 .709.904 1.709 1.237 3 1 5.021.78 10.021.113 15-2z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#A3A6AA"
                              fillRule="evenodd"
                              d="M419.5 4.5a6600.456 6600.456 0 01-163 3c.124.607.457.94 1 1 1.834 3.998 1 7.331-2.5 10l-.5 3.5a116.523 116.523 0 014 9.5 69.864 69.864 0 00-4 9.5 116.523 116.523 0 014 9.5 69.864 69.864 0 00-4 9.5 116.523 116.523 0 014 9.5 69.864 69.864 0 00-4 9.5 116.523 116.523 0 014 9.5l-4 9c-.063 1.981.604 3.648 2 5 54.329 1 108.663 1.333 163 1a3363.584 3363.584 0 01-164 2c-.645-1.312-1.645-2.312-3-3l-1-4.5a39.223 39.223 0 014-10c-1.977-2.678-3.31-5.678-4-9l.5-4.5c2.016-1.339 3.183-3.172 3.5-5.5-1.977-2.678-3.31-5.678-4-9l.5-4.5c2.016-1.339 3.183-3.172 3.5-5.5-1.977-2.678-3.31-5.678-4-9l.5-4.5c2.016-1.339 3.183-3.172 3.5-5.5-1.977-2.678-3.31-5.678-4-9l.5-4.5c2.016-1.339 3.183-3.172 3.5-5.5a58.716 58.716 0 00-4-7.5h168z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#CDCDCD"
                              fillRule="evenodd"
                              d="M344.5 62.5h76v10c-25.336.167-50.669 0-76-.5-3.938-3.113-3.938-6.28 0-9.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#C3C5C8"
                              fillRule="evenodd"
                              d="M419.5 103.5c.904.709 1.237 1.709 1 3a6808.314 6808.314 0 01-165-1c55.01.662 109.677-.004 164-2z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#EEB6B6"
                              fillRule="evenodd"
                              d="M45.5 110.5c.214 1.644-.12 3.144-1 4.5-3.649.963-6.982.463-10-1.5-1.068-.934-2.401-1.268-4-1v-7c3.663.452 7.33.952 11 1.5 1.824.66 3.157 1.827 4 3.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              style={{
                                fill: primaryColor,
                              }}
                              fillRule="evenodd"
                              d="M70.5 110.5a404.246 404.246 0 005.5 21 165.744 165.744 0 0111.5 10c.71 2.246 1.21 4.58 1.5 7 4.494 3.237 4.494 6.57 0 10-.667 3-.667 6 0 9 4.494 3.237 4.494 6.57 0 10-.667 3-.667 6 0 9 1.481.971 2.315 2.304 2.5 4-5.162 2.545-8.829 6.378-11 11.5-1.697.384-3.363.218-5-.5a5999.972 5999.972 0 01-9-8c-1.049-4.331.618-6.497 5-6.5a211.94 211.94 0 0012-12.5 406.298 406.298 0 00-25-23c-.06-.543-.393-.876-1-1-1.326 15.161-1.326 30.161 0 45 1.105 1.1 2.438 1.767 4 2a35.098 35.098 0 00-1.5 13 26.67 26.67 0 006.5 1.5c.752.671 1.086 1.504 1 2.5a73.9 73.9 0 00-10.5 13l-.5 3a34.767 34.767 0 003 6.5c-.63 1.31-1.63 2.144-3 2.5h-14c-2.011-.917-3.011-2.583-3-5a3926.022 3926.022 0 01-8-46c-.039-4.233-.705-8.233-2-12a300.134 300.134 0 01-1-13c-.19-4.87-.856-9.536-2-14a77.053 77.053 0 00-1-13 565.024 565.024 0 013-19c.934-1.068 1.268-2.401 1-4h5c3.018 1.963 6.351 2.463 10 1.5.88-1.356 1.214-2.856 1-4.5a429.333 429.333 0 0118-4c3.344-.303 5.678 1.03 7 4z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#6A6572"
                              fillRule="evenodd"
                              d="M28.5 112.5h2c1.599-.268 2.932.066 4 1h-5c.268 1.599-.066 2.932-1 4v-5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#F4BCBB"
                              fillRule="evenodd"
                              d="M105.5 111.5a56.798 56.798 0 018.5 9c.748 2.34.248 4.34-1.5 6h-4a102.264 102.264 0 01-8-11c1.039-2.031 2.705-3.364 5-4z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#A5A8AC"
                              fillRule="evenodd"
                              d="M108.5 126.5h4c52.677-.662 105.011.004 157 2 0 .667-.333 1-1 1-58.334-.167-116.668 0-175 .5 1.444 1.126 2.11 2.626 2 4.5l-4 10 4 9-4 10 4 9-4 10 4 9-4 10 4 9-4 10c-.11 1.874.556 3.374 2 4.5 58.332.5 116.666.667 175 .5.543.06.876.393 1 1-58.996 1-117.996 1.333-177 1-3.209-1.403-4.543-3.903-4-7.5a185.447 185.447 0 014-10c-.499-2.005-1.666-3.505-3.5-4.5l-.5-4.5a21.005 21.005 0 014-9c-.07-.765-.403-1.265-1-1.5-.185-1.696-1.019-3.029-2.5-4-.667-3-.667-6 0-9 4.494-3.43 4.494-6.763 0-10-.667-3-.667-6 0-9 4.494-3.43 4.494-6.763 0-10-.29-2.42-.79-4.754-1.5-7 3.002-1.52 4.669-4.02 5-7.5a16.534 16.534 0 01-4-7.5h20z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#FEFEFE"
                              fillRule="evenodd"
                              d="M268.5 129.5c1.024 3.862 3.357 6.695 7 8.5 10.656 3.907 19.323 1.407 26-7.5a145.036 145.036 0 0134 0c-2.02 4.595-1.02 8.261 3 11 1.141 1.921 1.308 3.921.5 6-5.979 4.904-5.979 9.904 0 15 1.048 3.98-.285 6.98-4 9-1.48 4.647-.314 8.313 3.5 11l1 3a42.906 42.906 0 01-4.5 7c-1.473 4.715-.14 8.381 4 11l.5 2.5c-2.228 2.813-3.895 5.98-5 9.5.375 3.711 2.042 6.711 5 9a324.95 324.95 0 01-36 1c-1.027-6.863-5.027-11.03-12-12.5-7.082-1.649-13.415-.315-19 4-2.282 2.397-3.616 5.231-4 8.5-58.334.167-116.668 0-175-.5-1.444-1.126-2.11-2.626-2-4.5l4-10-4-9 4-10-4-9 4-10-4-9 4-10-4-9 4-10c.11-1.874-.556-3.374-2-4.5 58.332-.5 116.666-.667 175-.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              style={{
                                fill: primaryColor,
                              }}
                              fillRule="evenodd"
                              d="M136.5 156.5c12.687-1.578 21.52 3.422 26.5 15 1.341 19.659-7.826 28.492-27.5 26.5-13.702-6.997-17.535-17.497-11.5-31.5 3.487-4.335 7.654-7.668 12.5-10z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              style={{
                                fill: primaryColor,
                              }}
                              fillOpacity="0.2"
                              fillRule="evenodd"
                              d="M338.5 157.5h14c.873 9.366 6.04 14.366 15.5 15 8.691-1.023 13.857-5.856 15.5-14.5 11.995-.5 23.995-.667 36-.5v102a324.95 324.95 0 01-36-1c-3.475-13.737-11.808-17.904-25-12.5-4.222 3.495-6.222 7.995-6 13.5-59.001.167-118.001 0-177-.5-2.841-1.63-4.174-4.13-4-7.5a24.94 24.94 0 014-10c-.228-1.897-1.228-3.23-3-4a21.127 21.127 0 01-1-9h99c1.208-9.2 6.541-13.867 16-14 8.961.632 13.961 5.299 15 14h41a24.935 24.935 0 00-.5-7c-4.206-1.07-5.539-3.737-4-8 1.11-1.537 2.61-2.203 4.5-2v-11c-4.651-.674-6.151-3.341-4.5-8 1.11-1.537 2.61-2.203 4.5-2v-10c-4.651-.674-6.151-3.341-4.5-8 1.11-1.537 2.61-2.203 4.5-2 .784-5.161-.55-9.495-4-13z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#CFCFCF"
                              fillRule="evenodd"
                              d="M181.5 162.5c13.337-.167 26.671 0 40 .5 4.895 2.823 5.229 6.157 1 10a420.29 420.29 0 01-41 0c-3.935-3.496-3.935-6.996 0-10.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#F1F3FA"
                              fillRule="evenodd"
                              d="M149.5 167.5h4a8.43 8.43 0 01-.5 4 90.887 90.887 0 00-9.5 12.5c-5.464.372-8.798-2.128-10-7.5 2.813.27 5.479.77 8 1.5a117.686 117.686 0 008-10.5z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#CFCFCF"
                              fillRule="evenodd"
                              d="M181.5 184.5c38.335-.167 76.668 0 115 .5 4 3.333 4 6.667 0 10-38.338.833-76.671.667-115-.5-3.896-3.242-3.896-6.575 0-10z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#EEB7B6"
                              fillRule="evenodd"
                              d="M66.5 193.5c3.026 2.695 6.026 5.362 9 8a63.707 63.707 0 01-6.5 7 20.106 20.106 0 00-1.5 6c.086-.996-.248-1.829-1-2.5a26.67 26.67 0 01-6.5-1.5 35.098 35.098 0 011.5-13c2.249-.58 3.915-1.913 5-4z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#A9ABAF"
                              fillRule="evenodd"
                              d="M338.5 157.5c3.45 3.505 4.784 7.839 4 13-1.89-.203-3.39.463-4.5 2-1.651 4.659-.151 7.326 4.5 8v10c-1.89-.203-3.39.463-4.5 2-1.651 4.659-.151 7.326 4.5 8v11c-1.89-.203-3.39.463-4.5 2-1.539 4.263-.206 6.93 4 8 .497 2.31.663 4.643.5 7h-41c-1.039-8.701-6.039-13.368-15-14-9.459.133-14.792 4.8-16 14h-99c-26.505.331-52.839-.002-79-1 59.004.333 118.004 0 177-1-.124-.607-.457-.94-1-1 .384-3.269 1.718-6.103 4-8.5 5.585-4.315 11.918-5.649 19-4 6.973 1.47 10.973 5.637 12 12.5a324.95 324.95 0 0036-1c-2.958-2.289-4.625-5.289-5-9 1.105-3.52 2.772-6.687 5-9.5l-.5-2.5c-4.14-2.619-5.473-6.285-4-11a42.906 42.906 0 004.5-7l-1-3c-3.814-2.687-4.98-6.353-3.5-11 3.715-2.02 5.048-5.02 4-9-5.979-5.096-5.979-10.096 0-15 .808-2.079.641-4.079-.5-6-4.02-2.739-5.02-6.405-3-11a145.036 145.036 0 00-34 0c-6.677 8.907-15.344 11.407-26 7.5-3.643-1.805-5.976-4.638-7-8.5.667 0 1-.333 1-1 7.507 10.065 16.507 11.565 27 4.5 1.719-1.87 2.719-4.037 3-6.5h43v3c-4.651.674-6.151 3.341-4.5 8 1.11 1.537 2.61 2.203 4.5 2v10c-1.89-.203-3.39.463-4.5 2-.789 2.212-.622 4.212.5 6z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#E4B0B1"
                              fillRule="evenodd"
                              d="M42.5 239.5h14v5c-4.615-.38-7.615 1.62-9 6v-10c-1.915.284-3.581-.049-5-1z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#353346"
                              fillRule="evenodd"
                              d="M56.5 244.5h3c.278 6.154 1.611 11.988 4 17.5-.175 1.658-.842 2.991-2 4 16.997.5 33.997.667 51 .5a1058.764 1058.764 0 01-92 0h27c-.095-2.286-.762-4.286-2-6-.19-3.532.477-6.865 2-10 1.385-4.38 4.385-6.38 9-6z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              style={{
                                fill: primaryColor,
                              }}
                              fillRule="evenodd"
                              d="M45.5 260.5c1.238 1.714 1.905 3.714 2 6h-27a332.95 332.95 0 0126-1.5c-.88-1.356-1.214-2.856-1-4.5zM20.5 266.5a1058.764 1058.764 0 0092 0c.543.06.876.393 1 1a1105.276 1105.276 0 01-94 0c.124-.607.457-.94 1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <p className="mb-4 text-[16px] font-bold text-gray-900 ">
                          Coupon Created Successfully!
                        </p>
                        {/* Show Coupon From response of redemtion */}
                        <div
                          style={{
                            backgroundColor: primaryColor,
                          }}
                          className="mt-[2.5rem] flex items-center justify-between rounded-md  "
                        >
                          <p className="w-full text-[16px] self-center font-semibold leading-4  text-white ">
                            {discountCodeData ? discountCodeData?.code : ""}
                          </p>
                          {/* Function to copy the code */}
                          <button
                            style={{
                              backgroundColor: primaryColor,
                              borderLeft: "1px solid #fff ",
                            }}
                            className="copy"
                            onClick={copyToClipboardCode}
                          >
                            <span
                              data-text-end="Copied!"
                              data-text-initial="Copy to clipboard"
                              className="tooltip"
                              style={{
                                backgroundColor: primaryColor,
                              }}
                            ></span>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                className="clipboard"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 6.35 6.35"
                              >
                                <path
                                  fill="currentColor"
                                  d="M2.43.265a.58.58 0 00-.573.53h-.328a.74.74 0 00-.735.734v3.822a.74.74 0 00.735.734H4.82a.74.74 0 00.735-.734V1.529A.74.74 0 004.82.794h-.328a.58.58 0 00-.573-.53zm0 .529h1.49c.032 0 .049.017.049.049v.431c0 .032-.017.049-.049.049H2.43c-.032 0-.05-.017-.05-.049V.843c0-.032.018-.05.05-.05zm-.901.53h.328a.581.581 0 00.573.528h1.49a.58.58 0 00.573-.529h.328a.2.2 0 01.206.206v3.822a.2.2 0 01-.206.205H1.53a.2.2 0 01-.206-.205V1.529a.2.2 0 01.206-.206z"
                                ></path>
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                className="checkmark"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                  data-original="#000000"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>

                        {/* <button data-modal-toggle="successModal" type="button" className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 ">
                                                Continue
                                            </button>    */}
                      </div>
                    </div>
                  </m.div>
                )}
              </div>
            </TabPanel>

            {/* Coupons Panel History*/}
            <TabPanel className="">
              {userCoupons?.length === 0 && (
                <div className="my-[3rem] flex flex-col h-full w-full items-center justify-center">
                  <svg
                    className="w-[12rem] h-[12rem] mt-[2.5rem]"
                    viewBox="0 0 304 283"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M141.5 283C219.648 283 283 219.648 283 141.5C283 63.3517 219.648 0 141.5 0C63.3517 0 0 63.3517 0 141.5C0 219.648 63.3517 283 141.5 283Z"
                      fill="#E6EBF3"
                    />
                    <mask
                      id="mask0_7_346"
                      maskUnits="userSpaceOnUse"
                      x="57"
                      y="120"
                      width="247"
                      height="133"
                    >
                      <path
                        d="M287.794 120.122H169.979C168.806 120.122 167.68 120.594 166.86 121.427L161.362 127.013C159.645 128.758 156.837 128.758 155.12 127.013L149.621 121.427C148.8 120.592 147.674 120.122 146.501 120.122H112.41C111.831 120.122 111.257 120.154 110.693 120.214H101.833C98.6199 120.214 95.7078 121.515 93.6013 123.624C91.4968 125.73 90.192 128.64 90.192 131.855C90.192 136.351 92.7426 140.254 96.4761 142.189C98.0795 143.022 99.9011 143.492 101.831 143.492H127.258C131.968 143.492 135.786 147.43 135.786 152.14C135.786 154.494 134.831 156.626 133.288 158.17C131.744 159.713 129.612 160.668 127.258 160.668H108.115C104.902 160.668 101.99 161.969 99.8834 164.077C97.7789 166.184 96.4741 169.092 96.4741 172.309C96.4741 178.736 101.687 183.949 108.115 183.949H136.248C143.08 183.949 148.621 189.489 148.621 196.323C148.621 199.74 147.236 202.833 144.996 205.071C142.758 207.311 139.663 208.696 136.248 208.696H66.4843C63.9337 208.696 61.6229 209.732 59.9507 211.402C58.2785 213.074 57.2429 215.387 57.2429 217.938C57.2429 223.043 61.3812 227.179 66.4843 227.179H132.204C135.218 227.179 137.666 229.624 137.666 232.642C137.666 234.149 137.053 235.515 136.065 236.503C135.079 237.49 133.711 238.103 132.204 238.103H118.417C116.389 238.103 114.55 238.926 113.222 240.256C111.893 241.585 111.07 243.424 111.07 245.452C111.07 249.51 114.36 252.799 118.417 252.799H146.501C147.676 252.799 148.802 252.327 149.621 251.494L155.12 245.908C156.837 244.163 159.645 244.163 161.362 245.908L166.86 251.494C167.682 252.329 168.808 252.799 169.979 252.799H287.794C296.596 252.799 303.729 245.666 303.729 236.867V136.054C303.729 127.255 296.596 120.122 287.794 120.122Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_7_346)">
                      <path
                        d="M303.727 136.054V236.867C303.727 245.666 296.594 252.799 287.794 252.799H169.979C168.806 252.799 167.68 252.327 166.858 251.492L161.358 245.904C159.643 244.161 156.833 244.161 155.118 245.904L149.618 251.492C148.796 252.327 147.67 252.799 146.497 252.799H112.406C103.607 252.799 96.4741 245.666 96.4741 236.867V136.054C96.4741 127.255 103.607 120.122 112.406 120.122H146.497C147.67 120.122 148.796 120.594 149.618 121.429L155.118 127.017C156.833 128.76 159.643 128.76 161.358 127.017L166.858 121.429C167.68 120.594 168.806 120.122 169.979 120.122H287.794C296.594 120.122 303.727 127.255 303.727 136.054Z"
                        style={{
                          fill: primaryColor,
                        }}
                      />
                      <path
                        d="M218.173 149.059C218.173 192.961 182.583 228.551 138.681 228.551C123.173 228.551 108.705 224.11 96.4766 216.431V136.054C96.4766 127.255 103.61 120.122 112.409 120.122H146.5C147.673 120.122 148.799 120.594 149.62 121.429L155.12 127.017C156.835 128.76 159.645 128.76 161.361 127.017L166.861 121.429C167.682 120.594 168.808 120.122 169.981 120.122H212.742C216.249 129.088 218.175 138.851 218.175 149.059H218.173Z"
                        fill={primaryColor}
                      />
                      <path
                        d="M161.213 140.252H154.351V150.77H161.213V140.252Z"
                        fill="white"
                      />
                      <path
                        d="M161.213 160.67H154.351V171.189H161.213V160.67Z"
                        fill="white"
                      />
                      <path
                        d="M161.213 181.086H154.351V191.605H161.213V181.086Z"
                        fill="white"
                      />
                      <path
                        d="M161.213 201.505H154.351V212.023H161.213V201.505Z"
                        fill="white"
                      />
                      <path
                        d="M161.213 221.923H154.351V232.442H161.213V221.923Z"
                        fill="white"
                      />
                      <path
                        d="M212.063 230.03C211.381 230.03 210.69 229.856 210.057 229.488C208.145 228.378 207.497 225.928 208.607 224.016L254.586 144.867C255.696 142.955 258.146 142.307 260.058 143.417C261.97 144.527 262.619 146.978 261.509 148.89L215.53 228.038C214.787 229.317 213.445 230.03 212.065 230.03H212.063Z"
                        fill="white"
                      />
                      <path
                        d="M207.526 188.019C202.635 188.019 197.898 186.447 193.915 183.46C189.051 179.811 185.899 174.488 185.041 168.469C184.182 162.45 185.719 156.457 189.366 151.594C193.015 146.73 198.338 143.578 204.357 142.72C216.779 140.947 228.332 149.611 230.106 162.036C231.878 174.46 223.213 186.011 210.79 187.785C209.699 187.94 208.609 188.017 207.526 188.017V188.019ZM207.583 150.497C206.891 150.497 206.192 150.546 205.488 150.646C201.586 151.203 198.135 153.246 195.772 156.398C193.406 159.55 192.411 163.435 192.967 167.337C193.524 171.24 195.567 174.69 198.719 177.054C201.871 179.42 205.756 180.414 209.658 179.858C217.713 178.709 223.331 171.222 222.181 163.167C221.132 155.816 214.805 150.497 207.583 150.497Z"
                        fill="white"
                      />
                      <path
                        d="M262.491 230.418C257.6 230.418 252.863 228.846 248.88 225.859C244.016 222.21 240.864 216.887 240.006 210.868C238.233 198.443 246.899 186.893 259.322 185.118C265.34 184.26 271.334 185.796 276.197 189.443C281.06 193.092 284.212 198.416 285.071 204.434C285.93 210.453 284.393 216.446 280.746 221.31C277.097 226.173 271.774 229.325 265.755 230.184C264.664 230.339 263.574 230.416 262.491 230.416V230.418ZM262.57 192.894C261.868 192.894 261.161 192.943 260.453 193.045C252.399 194.195 246.783 201.683 247.93 209.736C248.487 213.638 250.53 217.089 253.682 219.453C256.834 221.819 260.719 222.815 264.621 222.257C268.524 221.701 271.974 219.657 274.338 216.505C276.704 213.353 277.698 209.469 277.142 205.566C276.586 201.664 274.542 198.213 271.391 195.847C268.809 193.912 265.737 192.892 262.568 192.892L262.57 192.894Z"
                        fill="white"
                      />
                      <path
                        d="M109.854 120.122H79.0308V145.512H109.854V120.122Z"
                        fill={primaryColor}
                      />
                      <path
                        d="M111.182 205.686H49.5361V232.257H111.182V205.686Z"
                        style={{
                          fill: primaryColor,
                        }}
                      />
                      <path
                        d="M111.182 205.686V223.544C100.626 219.604 91.1391 213.466 83.2771 205.686H111.182Z"
                        fill={primaryColor}
                      />
                    </g>
                    <path
                      d="M80.5223 172.311C80.5223 165.882 75.311 160.67 68.8826 160.67C62.4542 160.67 57.2429 165.882 57.2429 172.311C57.2429 178.74 62.4542 183.951 68.8826 183.951C75.311 183.951 80.5223 178.74 80.5223 172.311Z"
                      fill={primaryColor}
                    />
                    <path
                      d="M95.2185 245.451C95.2185 241.393 91.9286 238.103 87.8703 238.103C83.8121 238.103 80.5222 241.393 80.5222 245.451C80.5222 249.509 83.8121 252.799 87.8703 252.799C91.9286 252.799 95.2185 249.509 95.2185 245.451Z"
                      style={{
                        fill: primaryColor,
                      }}
                    />
                    <path
                      d="M161.869 33.8056H125.949C117.942 33.8056 111.451 40.2965 111.451 48.3034V84.2236C111.451 92.2305 117.942 98.7213 125.949 98.7213H161.869C169.876 98.7213 176.367 92.2305 176.367 84.2236V48.3034C176.367 40.2965 169.876 33.8056 161.869 33.8056Z"
                      fill="white"
                    />
                    <path
                      d="M161.869 98.7213V107.959L148.657 98.7213H161.869Z"
                      fill="white"
                    />
                    <path
                      d="M149.918 66.2634L160.924 55.2575C162.583 53.599 162.583 50.907 160.924 49.2485C159.266 47.5901 156.574 47.5901 154.915 49.2485L143.909 60.2545L132.903 49.2485C131.245 47.5901 128.553 47.5901 126.894 49.2485C125.236 50.907 125.236 53.599 126.894 55.2575L137.9 66.2634L126.894 77.2694C125.236 78.9279 125.236 81.6199 126.894 83.2784C127.723 84.1076 128.812 84.5222 129.899 84.5222C130.985 84.5222 132.074 84.1076 132.903 83.2784L143.909 72.2724L154.915 83.2784C155.744 84.1076 156.833 84.5222 157.92 84.5222C159.006 84.5222 160.095 84.1076 160.924 83.2784C162.583 81.6199 162.583 78.9279 160.924 77.2694L149.918 66.2634Z"
                      fill="#FF3342"
                    />
                    <path
                      d="M65.3388 79.1283C67.0458 79.1283 68.4297 77.7444 68.4297 76.0374C68.4297 74.3303 67.0458 72.9464 65.3388 72.9464C63.6317 72.9464 62.2478 74.3303 62.2478 76.0374C62.2478 77.7444 63.6317 79.1283 65.3388 79.1283Z"
                      style={{
                        fill: primaryColor,
                      }}
                    />
                    <path
                      d="M39.2691 281.907C42.1765 281.907 44.5334 279.55 44.5334 276.642C44.5334 273.735 42.1765 271.378 39.2691 271.378C36.3618 271.378 34.0049 273.735 34.0049 276.642C34.0049 279.55 36.3618 281.907 39.2691 281.907Z"
                      fill="#E6EBF3"
                    />
                    <path
                      d="M288.72 75.6527C289.289 72.1445 286.907 68.8388 283.399 68.2694C279.891 67.6999 276.585 70.0822 276.015 73.5905C275.446 77.0987 277.828 80.4044 281.337 80.9738C284.845 81.5433 288.15 79.161 288.72 75.6527Z"
                      style={{
                        fill: primaryColor,
                      }}
                    />
                  </svg>

                  <p
                    style={{
                      color: primaryColor,
                    }}
                    className=" text-[18px] my-[12px] font-bold leading-4"
                  >
                    No coupons yet!
                  </p>
                </div>
              )}
              {userCoupons?.length > 0 && (
                <div className="my-[3rem]  h-full w-full ">
                  <Accordion allowZeroExpanded>
                    {userCoupons.map((coupon, i) => (
                      <AccordionItem
                        className="accordionItem"
                        key={coupon.CouponNumber}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <h5
                              style={{
                                color: primaryColor,
                              }}
                              className="accordionHeader"
                            >
                              {coupon.Value} EGP Coupon
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="flex flex-col">
                            <div
                              style={{
                                backgroundColor: `${primaryColor}${opacity}`,
                              }}
                              className="p-[20px]  flex items-start justify-between"
                            >
                              <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                                Coupon Details
                              </h6>
                              <p className="font-bold text-black leading-4 text-[14px]">
                                {coupon.Value} EGP
                              </p>
                            </div>

                            <div className="p-[20px] flex items-start justify-between">
                              <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                                Coupon Code
                              </h6>
                              <div className="flex gap-2">
                                <p className="font-bold text-black leading-4 text-[14px]">
                                  {coupon.CouponNumber}
                                </p>
                                <div className="group relative">
                                  <svg
                                    className={`copySvgIcon cursor-pointer w-[16px] h-[16px]`}
                                    onClick={() =>
                                      copyToClipboard(coupon.CouponNumber, i)
                                    }
                                    viewBox="0 0 467 512.22"
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    imageRendering="optimizeQuality"
                                    textRendering="geometricPrecision"
                                    shapeRendering="geometricPrecision"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000"
                                  >
                                    <path
                                      d="M131.07 372.11c.37 1 .57 2.08.57 3.2 0 1.13-.2 2.21-.57 3.21v75.91c0 10.74 4.41 20.53 11.5 27.62s16.87 11.49 27.62 11.49h239.02c10.75 0 20.53-4.4 27.62-11.49s11.49-16.88 11.49-27.62V152.42c0-10.55-4.21-20.15-11.02-27.18l-.47-.43c-7.09-7.09-16.87-11.5-27.62-11.5H170.19c-10.75 0-20.53 4.41-27.62 11.5s-11.5 16.87-11.5 27.61v219.69zm-18.67 12.54H57.23c-15.82 0-30.1-6.58-40.45-17.11C6.41 356.97 0 342.4 0 326.52V57.79c0-15.86 6.5-30.3 16.97-40.78l.04-.04C27.51 6.49 41.94 0 57.79 0h243.63c15.87 0 30.3 6.51 40.77 16.98l.03.03c10.48 10.48 16.99 24.93 16.99 40.78v36.85h50c15.9 0 30.36 6.5 40.82 16.96l.54.58c10.15 10.44 16.43 24.66 16.43 40.24v302.01c0 15.9-6.5 30.36-16.96 40.82-10.47 10.47-24.93 16.97-40.83 16.97H170.19c-15.9 0-30.35-6.5-40.82-16.97-10.47-10.46-16.97-24.92-16.97-40.82v-69.78zM340.54 94.64V57.79c0-10.74-4.41-20.53-11.5-27.63-7.09-7.08-16.86-11.48-27.62-11.48H57.79c-10.78 0-20.56 4.38-27.62 11.45l-.04.04c-7.06 7.06-11.45 16.84-11.45 27.62v268.73c0 10.86 4.34 20.79 11.38 27.97 6.95 7.07 16.54 11.49 27.17 11.49h55.17V152.42c0-15.9 6.5-30.35 16.97-40.82 10.47-10.47 24.92-16.96 40.82-16.96h170.35z"
                                      fillRule="nonzero"
                                    ></path>
                                  </svg>
                                  <span
                                    style={{
                                      backgroundColor: primaryColor,
                                    }}
                                    className="absolute top-[-2.5rem] right-[-1rem] scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"
                                  >
                                    {copiedIndex === i && textCopied
                                      ? "Copied"
                                      : "Copy"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                backgroundColor: `${primaryColor}${opacity}`,
                              }}
                              className="p-[20px]  flex items-start justify-between"
                            >
                              <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                                Expiry Date
                              </h6>
                              <p className="font-bold text-black leading-4 text-[14px]">
                                {convertDate(coupon.ExpiryDate)}
                              </p>
                            </div>
                            {/* <div className='p-[20px] flex items-start justify-between'>
                                                            <h6 className='text-[#1D1D1B] text-[16px] font-medium leading-4'>
                                                                Status
                                                            </h6>
                                                            <p className='font-bold text-[#1FB981] leading-4 text-[14px]'>
                                                                {coupon.Status}
                                                            </p>
                                                        </div> */}
                            <div className="p-[20px] flex items-start justify-between">
                              <h6 className="text-[#1D1D1B] text-[16px] font-medium leading-4">
                                Status
                              </h6>
                              <p
                                className={`font-bold ${
                                  coupon.Status === "Subscribed"
                                    ? "text-green-500"
                                    : "text-red-500"
                                }  leading-4 text-[14px]`}
                              >
                                {coupon.Status}
                              </p>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              )}
            </TabPanel>
          </Tabs>
        </m.div>
      </AnimatePresence>
    </>
  );
});

export default React.memo(Redeem);
